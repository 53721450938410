import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element.js';
import '@assets/css/reset.css';

import '@/assets/css/animate.css';
import axios from 'axios';

import VueLazyLoad from 'vue-lazyload';
Vue.use(VueLazyLoad, {
  preLoad: 1,
  error: require('./assets/img/err.png'), // 错误图片
  loading: require('./assets/img/jzz.png'), // 加载中图片
  attempt: 2,
});

Vue.config.productionTip = false;
// 图片地址
Vue.prototype.$getImg = function getImg(url) {
  // return 'http://www.tezgc.com/' + url;
  return 'http://www.tezgc.com' + url;
};

new Vue({
  axios,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
