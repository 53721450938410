import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'home',
    component: () => import('@/views/home'),
  },
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: () => import('@/views/home'),
  },
  // {
  //   path: '/home2',
  //   name: 'home2',
  //   component: () => import('@/views/home2'),
  // },
  // {
  //   path: '/home02',
  //   name: 'home02',
  //   component: () => import('@/views/home02'),
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/gaishu',
    name: 'gaishu',
    component: (resolve) => require(['../views/aboutGuCheng/gaishu'], resolve),
  },
  {
    path: '/yuanyuan',
    name: 'yuanyuan',
    component: (resolve) =>
      require(['../views/aboutGuCheng/yuanyuan'], resolve),
  },
  {
    path: '/mygc',
    name: 'mygc',
    component: (resolve) => require(['../views/manyou/mygc'], resolve),
  },
  {
    path: '/Notice', //公告资讯
    name: 'Notice',
    component: (resolve) => require(['../views/Notice'], resolve),
  },
  {
    path: '/EventPerformance', //活动演艺
    name: 'EventPerformance',
    component: (resolve) => require(['../views/EventPerformance'], resolve),
  },
  {
    path: '/TransportationGuide',
    name: 'TransportationGuide',
    component: (resolve) => require(['../views/TransportationGuide'], resolve),
  },
  {
    path: '/ItemList',
    name: 'ItemList',
    component: (resolve) => require(['../views/ItemList'], resolve),
  },
  {
    path: '/TicketPolicy',
    name: 'TicketPolicy',
    component: (resolve) => require(['../views/TicketPolicy'], resolve),
  },
  // {
  //   path: '/LxAnswer',
  //   name: 'LxAnswer',
  //   component: (resolve) => require(['../views/LxAnswer'], resolve),
  // },
  {
    path: '/TouristMessage',
    name: 'TouristMessage',
    component: (resolve) => require(['../views/TouristMessage'], resolve),
  },
  {
    path: '/GlDetails', //详情
    name: 'GlDetails',
    component: (resolve) => require(['../views/GlDetails'], resolve),
  },
  {
    path: '/GlDetailsnew', //详情
    name: 'GlDetailsnew',
    component: (resolve) => require(['../views/GlDetailsnew'], resolve),
  },{
    path: '/MyDetails', //详情
    name: 'MyDetails',
    component: (resolve) => require(['../views/MyDetails'], resolve),
  },
  {
    path: '/MyDetails', //详情
    name: 'MyDetails',
    component: (resolve) => require(['../views/MyDetails'], resolve),
  },
  ,
  {
    path: '/xiangqing', //详情
    name: 'xiangqing',
    component: (resolve) =>
      require(['../views/aboutGuCheng/xiangqing'], resolve),
  },
  {
    path: '/guidtour',
    name: 'guidtour',
    component: (resolve) => require(['../views/guidTour'], resolve),
  },
  {
    path: '/LxAnswer',
    name: 'problemAnswer',
    component: (resolve) => require(['../views/problemAnswer'], resolve),
  },
];
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
