<template>
	<div id="app">
		<!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
		<router-view />
	</div>
</template>
<script>
	export default {
		watch: {
			$route: {
				//执行 val:当前最新值 old:上一刻的值
				handler: function(val, old) {
					if (val.name == 'home' && old.name != null) {
						// 刷新页面
						location.reload();
					}
				},
				// 立即处理 进入页面就触发
				immediate: true,
				// 深度监听 属性的变化
				deep: true,
			},
			"$store.state.color": {
				deep: true,
				handler(newValue, oldValue) {
					let color = this.$store.state.color
					// 轮播图
					let dom = document.getElementsByClassName('el-carousel__indicator--horizontal')
					for (let i = 0; i < dom.length; i++) {
						dom[i].style.background = '#fff'
						let className = dom[i].className
						if (className.indexOf('is-active') != -1) {
							dom[i].style.background = color
						}
					}
					// QHcolor全局背景颜色
					let dom2 = document.getElementsByClassName('QHcolor')
					for (let i = 0; i < dom2.length; i++) {
						dom2[i].style.background = color
					}
					// QHcolor2全局字体颜色
					let dom3 = document.getElementsByClassName('QHcolor2')
					for (let i = 0; i < dom3.length; i++) {
						dom3[i].style.color = color
					}
					// QHborderColor全局边框颜色
					let dom4 = document.getElementsByClassName('QHborderColor')
					for (let i = 0; i < dom4.length; i++) {
						dom4[i].style.borderColor = color
					}
					//轮播图2
					let dom5 = document.getElementsByClassName('swiper-pagination-bullet')
					// console.log(dom5)
					for (let i = 0; i < dom.length; i++) {
						let className = dom5[i].className
						if (className.indexOf('swiper-pagination-bullet-active') != -1) {
							dom5[i].children[0].style.background = color
						} else {
							dom5[i].children[0].style.background = '#b5b5b5'
						}
					}
				}
			}
		},
	};
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-align: center; */
		color: #2c3e50;
	}

	nav {
		padding: 30px;
	}

	nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	nav a.router-link-exact-active {
		color: #42b983;
	}
</style>
