import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		color: ''
	},
	getters: {},
	mutations: {
		// 保存当前菜单栏的路径
		setColor(state, value) {
			state.color = value;
		},
	},
	actions: {},
	modules: {}
})
